<script>
    import appConfig from "@/app.config";
    
    import Swal from "sweetalert2";
    import axios from "axios";
    /**
     * Dashboard Component
     */
    export default {
        page: {
            title: "Edit Posisi",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        components: {
            // Layout,
            // PageHeader,
        },
        props: ["idData"],
        data() {
            return {
                title: "Edit Posisi",
                items: [
                    {
                        text: "Master",
                        href: "/",
                    },
                    {
                        text: "Posisi",
                        href: "/master/Posisi",
                    },
                    {
                        text: "Edit Posisi",
                        active: true,
                    },
                ],
                // Catch Error Axios
                axiosCatchError: null,
                // variable Page
                master_role: ['one', 'two', 'three'],
                dataid: this.idData,
                posisi: '',
            };
        },
        mounted() {
            this.getPosisi();
        },
        methods:{
            getPosisi() {
                Swal.fire({
                    title: '<i class="fas fa-spinner fa-spin"></i>',
                    text: "Loading...",
                    showConfirmButton: false,
                    allowOutsideClick: false,
                });
                this.dataid = this.idData;
                let self = this;
                axios
                .get(
                process.env.VUE_APP_BACKEND_URL_VERSION +
                    "/api/master/posisi?id=" +
                    this.dataid
                )
                .then((response) => {
                var response_data = response.data;
                var response_data_fix = response_data.list_data.data[0];
                if (response_data.code == 200) {
                    self.posisi = response_data_fix.mp_nama;
                } else {
                    Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: response_data.message,
                    });
                }
                Swal.close();
                });
            },
            StoreData() {
                let self = this;
                Swal.fire({
                    title: '<i class="fas fa-spinner fa-spin"></i>',
                    text: "Loading...",
                    showConfirmButton: false,
                });
                var FormData = require("form-data");
                var data = new FormData();
                data.append("id", self.dataid);
                data.append("posisi", self.posisi);

                var config = {
                    method: "post",
                    url:
                    process.env.VUE_APP_BACKEND_URL_VERSION + "/api/master/posisi/update",
                    data: data,
                };
                axios(config)
                .then(function (response) {
                var response_data = response.data; 
                if(response_data.code != 200){
                    Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    html: response_data.message,
                    });
                }else{
                    let timerInterval;
                    Swal.fire({
                    icon: "success",
                    title: "Berhasil",
                    text: "Anda akan diarahkan ke halaman master posisi segera",
                    timer: 2000,
                    timerProgressBar: true,
                    showCancelButton: false,
                    showConfirmButton: false,
                    willClose: () => {
                        clearInterval(timerInterval);
                    },
                    }).then((result) => {
                    /* Read more about handling dismissals below */
                    if (result.dismiss === Swal.DismissReason.timer) {
                        self.$emit("refresh-table", "update");
                    }
                    });
                }
                })
                .catch((e) => {
                    this.axiosCatchError = e.response.data.data;
                    Swal.close();
                });
            },
        },
    };
</script>

<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <b-form class="p-2" @submit.prevent="StoreData">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <b-form-group class="mb-3" label="Posisi" label-for="formrow-Posisi-input">
                                    <b-form-input id="formrow-Posisi-input" type="text" v-model="posisi"></b-form-input>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <b-button type="submit" variant="primary" class="m-1"><i class="fa fa-save"></i> Simpan</b-button>
                        <b-button type="reset" variant="danger" class="m-1"><i class="fa fa-refresh"></i> Reset</b-button>
                    </div>
                </b-form>
            </div>
        </div>
    </div>
</template>
